import React from 'react';

const Footer = () => (
  <div className="footer">
    <div className="footer-container">
      <p>Footer Block</p>
      <p>All rights reserved</p>
    </div>
  </div>
);

export default Footer;
