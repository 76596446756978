// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataRangeFilter_filters__ARmeT {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .DataRangeFilter_filters__ARmeT {
    row-gap: 1rem;
  }
}

.DataRangeFilter_calendar-icon__MEhn- {
  width: 24px;
  height: 24px;
  background-size: cover;
}

.DataRangeFilter_calendar-container__R0xzt {
  position: relative;
}

.DataRangeFilter_calendar-popup__LPVVM {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/entities/dateRangeCalendar/ui/DataRangeFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EALF;IAMI,aAAA;EAGF;AACF;;AAAA;EACE,WAAA;EACA,YAAA;EAEA,sBAAA;AAEF;;AACA;EACE,kBAAA;AAEF;;AACA;EACE,kBAAA;EACA,qBAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,sCAAA;EACA,YAAA;AAEF","sourcesContent":[".filters {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  flex-wrap: wrap;\n  @media screen and (max-width: 768px) {\n    row-gap: 1rem;\n  }\n}\n\n.calendar-icon {\n  width: 24px;\n  height: 24px;\n  //background-image: url('calendar-icon.png');\n  background-size: cover;\n}\n\n.calendar-container {\n  position: relative;\n}\n\n.calendar-popup {\n  position: absolute;\n  top: calc(100% + 8px);\n  left: 0;\n  z-index: 1000;\n  background-color: white;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `DataRangeFilter_filters__ARmeT`,
	"calendar-icon": `DataRangeFilter_calendar-icon__MEhn-`,
	"calendar-container": `DataRangeFilter_calendar-container__R0xzt`,
	"calendar-popup": `DataRangeFilter_calendar-popup__LPVVM`
};
export default ___CSS_LOADER_EXPORT___;
