// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_logo__ue9UR {
  position: fixed;
  display: flex;
  z-index: 11;
  top: 0;
  width: 250px;
  height: 70px;
  align-items: center;
  justify-content: center;
}

.Sidebar_appBar__RiAio {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: #2a3042;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  transition: all 0.3s ease;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/authLayout/sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,WAAA;EACA,MAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;AAEF","sourcesContent":[".logo {\n  position: fixed;\n  display: flex;\n  z-index: 11;\n  top: 0;\n  width: 250px;\n  height: 70px;\n  align-items: center;\n  justify-content: center;\n}\n.appBar {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 70px;\n  background: #2a3042;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  transition: all 0.3s ease;\n  z-index: 100;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Sidebar_logo__ue9UR`,
	"appBar": `Sidebar_appBar__RiAio`
};
export default ___CSS_LOADER_EXPORT___;
