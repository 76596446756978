export const names = {
  Home: 'Home',
  Dashboard: 'Dashboard',
  Partners: 'Partners',
  Games: 'Games',
  СurrencyGames: 'Currency/Games',
  SearchPlayer: 'SearchPlayer',
  Sessions: 'Sessions',
  SessionEvents: 'Session Events',
  Login: 'Login',
  SessionsForGameCurrency: 'Sessions For Game Currency',
  PartnerCurrency: 'Partner Currenсy',
};
