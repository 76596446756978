// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 12px 0 0;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/authLayout/header/Header.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,YAAA;AAEF","sourcesContent":[".navbar_header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 auto;\n  height: 70px;\n  padding: 0 calc(24px * 0.5) 0 0;\n}\n.header-profile-user {\n  height: 36px;\n  width: 36px;\n  padding: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
